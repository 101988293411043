import React from "react";
import { NoSsr, Typography } from "@material-ui/core";

import { Layout, TopNavigation } from "components";
import "../styles/privacy-policy.css";

export default function PrivacyPolicy({ location }) {
  const seo = {
    title: "",
    description: "",
  };

  return (
    <Layout location={location} seo={seo}>
      <TopNavigation />
      <section style={{ padding: "20px" }}>
        <NoSsr>
          <Typography variant="h4">Terms and Conditions</Typography>
          <Typography variant="body1" gutterBottom>
            Last updated: December 23, 2020
          </Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            Please read these terms and conditions carefully before using Our
            Service.
          </Typography>
          <br />
          <Typography gutterBottom variant="h4">
            Interpretation and Definitions
          </Typography>
          <br />
          <Typography variant="h4">Interpretation</Typography>
          <Typography variant="body1" gutterBottom>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </Typography>
          <br />
          <Typography variant="h4">
            Definitions
          </Typography>
          <Typography variant="body1">
            For the purposes of these Terms and Conditions:
            <Typography variant="body1">
              <ul>
                <li>
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where "control" means
                  ownership of 50% or more of the shares, equity interest or
                  other securities entitled to vote for election of directors or
                  other managing authority.
                </li>
                <li>
                  Application means the software program provided by the Company
                  downloaded by You on any electronic device, named Documentary
                  Plus.
                </li>
                <li>
                  Country refers to the United States of America and State
                  refers to the State of California
                </li>
                <li>
                  Company (referred to as either "the Company", "We", "Us" or
                  "Our") refers to Documentary Plus, LLC, 10960 Wilshire Blvd.
                  Ste 1900 Los Angeles, California 90024-3805.
                </li>
                <li>
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </li>
                <li>
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where "control" means
                  ownership of 50% or more of the shares, equity interest or
                  other securities entitled to vote for election of directors or
                  other managing authority.
                </li>
                <li>
                  Application means the software program provided by the Company
                  downloaded by You on any electronic device, named Documentary
                  Plus.
                </li>
                <li>
                  Country refers to the United States of America and State
                  refers to the State of California
                </li>
                <li>
                  Company (referred to as either "the Company", "We", "Us" or
                  "Our") refers to Documentary Plus, LLC, 10960 Wilshire Blvd.
                  Ste 1900 Los Angeles, California 90024-3805.
                </li>
                <li>
                  Service refers to providing access to certain entertainment
                  properties and related services by way of the Website.
                </li>
                Terms and Conditions (also referred as "Terms") mean these Terms
                and Conditions which, together with Company’s privacy policy and
                any other legal notices posted on the Website, form the entire
                agreement between You and the Company regarding the use of the
                Service. This Terms and Conditions agreement has been created
                with the help of the{" "}
                <a href="https://www.termsfeed.com/terms-conditions-generator/">
                  Terms and Conditions Generator.
                </a>
                <li>
                  Third-party Social Media Service means any services or content
                  (including data, information, products or services) provided
                  by a third-party that may be displayed, included or made
                  available by the Service.
                </li>
                <li>
                  Website refers to Documentary Plus, accessible from{" "}
                  <a href="https://docplus.com">https://docplus.com.</a>
                </li>
                <li>
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </li>
              </ul>
            </Typography>
          </Typography>
          <br />
          <Typography variant="h4">Acknowledgment</Typography>
          <Typography variant="body1">
            These are the Terms and Conditions governing the use of this Service
            and, together with Company’s privacy policy and any other legal
            notices posted on the Website, is the agreement that governs the
            relationship between You and the Company. These Terms and Conditions
            set out the rights and obligations of all users regarding the use of
            the Service.
            <br /> Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Service. <br />
            By accessing or using the Service You agree to be bound by these
            Terms and Conditions. If You disagree with any part of these Terms
            and Conditions then You may not access the Service. <br />
            You represent that you are over the age of 18. The Company does not
            permit those under 18 to use the Service. Your access to and use of
            the Service is also conditioned on Your acceptance of and compliance
            with Company’s Privacy Policy and any other legal notice or policy
            posted on the Website. Our Privacy Policy describes Our policies and
            procedures on the collection, use and disclosure of Your personal
            information when You use the Application or the Website and tells
            You about Your privacy rights and how the law protects You. Please
            read Our Privacy Policy carefully before using Our Service.
          </Typography>
          <br />
          <br />
          <Typography gutterBottom variant="h4">
            Links to Other Websites
            <Typography variant="body1">
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by the Company.
              <br />
              The Company has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any
              third-party websites or services. You further acknowledge and
              agree that the Company shall not be responsible or liable,
              directly or indirectly, for any damage or loss caused or alleged
              to be caused by or in connection with the use of or reliance on
              any such content, goods or services available on or through any
              such websites or services.
              <br />
              We strongly advise You to read the terms and conditions and
              privacy policies of any third-party websites or services that You
              visit.
            </Typography>
          </Typography>
          <br />
          <br />
          <Typography gutterBottom variant="h4">
            Intellectual Property Rights
            <Typography variant="body1">
              The content on the Website, including without limitation, the
              text, software, scripts, graphics, files, images, photos, sounds,
              music, videos, interactive features and the like (collectively
              "Content") and the trademarks, service marks and logos contained
              therein (collectively "Marks"), are owned by or licensed to
              Company, subject to copyright and other intellectual property
              rights under United States and foreign laws and international
              conventions. Unless otherwise expressly set forth herein, Company
              reserves all rights in and to the Website and the Content owned by
              or licensed to Company. You agree to not use, copy, reproduce,
              modify, translate, publish, broadcast, transmit, distribute,
              perform, upload, display, license, sell or otherwise exploit for
              any other purposes whatsoever any Content, including, but not
              limited to, image, audio, and visual content, Marks, or other
              proprietary rights not owned by you, (i) without the express prior
              written consent of the respective owners in each instance, or (ii)
              in any way that violates any right of any third party. If and to
              the extent permitted herein, if you download or print a copy of
              the Content, or any portion thereof, for personal use, you must
              retain all copyright and other proprietary notices contained
              therein. You agree not to circumvent, disable or otherwise
              interfere with security related features of the Website or
              features that prevent or restrict use or copying of any Content or
              enforce limitations on use of the Website, the Content and/or the
              Marks.
            </Typography>
          </Typography>
          <br />
          <br />
          <Typography gutterBottom variant="h4">
            Termination
            <Typography variant="body1">
              We may terminate or suspend Your access immediately, without prior
              notice or liability, for any reason whatsoever, including without
              limitation if You breach these Terms and Conditions.
              <br />
              Upon termination, Your right to use the Service will cease
              immediately.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography gutterBottom variant="h4">
            Limitation of Liability
            <Typography variant="body1">
              Notwithstanding any damages that You might incur, the entire
              liability of the Company and any of its suppliers under any
              provision of these Terms, and Your exclusive remedy for all of the
              foregoing, shall be limited to the amount, if any, actually paid
              by You through the Service or 100 USD if You haven't purchased
              anything through the Service.
              <br />
              To the maximum extent permitted by applicable law, in no event
              shall the Company or its suppliers be liable for any special,
              incidental, indirect, or consequential damages whatsoever
              (including, but not limited to, damages for loss of profits, loss
              of data or other information, for business interruption, for
              personal injury, loss of privacy arising out of or in any way
              related to the use of or inability to use the Service, third-party
              software and/or third-party hardware used with the Service, or
              otherwise in connection with any provision of these Terms), even
              if the Company or any supplier has been advised of the possibility
              of such damages and even if the remedy fails of its essential
              purpose.
              <br />
              Some states or other jurisdictions do not allow the exclusion of
              implied warranties or limitation of liability for incidental,
              consequential and/or other forms of damages, which means that some
              of the above limitations may not apply. In these states or other
              jurisdictions, each party's liability will be limited to the
              greatest extent permitted by the applicable law.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography gutterBottom variant="h4">
            "AS IS" and "AS AVAILABLE" Disclaimer
            <Typography variant="body1">
              The Service is provided to You "AS IS" and "AS AVAILABLE" and with
              all faults and defects without warranty of any kind. To the
              maximum extent permitted under applicable law, the Company, on its
              own behalf and on behalf of its Affiliates and its and their
              respective licensors and service providers, expressly disclaims
              all warranties, whether express, implied, statutory or otherwise,
              with respect to the Service, including all implied warranties of
              merchantability, fitness for a particular purpose, title and
              non-infringement, and warranties that may arise out of course of
              dealing, course of performance, usage or trade practice. Without
              limitation to the foregoing, the Company provides no warranty or
              undertaking, and makes no representation of any kind that the
              Service will meet Your requirements, achieve any intended results,
              be compatible or work with any other software, applications,
              systems or services, operate without interruption, meet any
              performance or reliability standards or be error free or that any
              errors or defects can or will be corrected.
              <br />
              Without limiting the foregoing, neither the Company nor any of the
              company's provider makes any representation or warranty of any
              kind, express or implied: (i) as to the operation or availability
              of the Service, or the information, content, and materials or
              products included thereon; (ii) that the Service will be
              uninterrupted or error-free; (iii) as to the accuracy,
              reliability, or currency of any information or content provided
              through the Service; or (iv) that the Service, its servers, the
              content, or e-mails sent from or on behalf of the Company are free
              of viruses, scripts, trojan horses, worms, malware, timebombs or
              other harmful components. Some jurisdictions do not allow the
              exclusion of certain types of warranties or limitations on
              applicable statutory rights of a consumer, so some or all of the
              above exclusions and limitations may not apply to You. But in such
              a case the exclusions and limitations set forth in this section
              shall be applied to the greatest extent enforceable under
              applicable law.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            Governing Law
          </Typography>
          <Typography variant="body1">
            These Terms of Service shall be governed by the internal substantive
            laws of the State of California, without respect to its conflict of
            laws principles. Any claim or dispute between Company and You that
            arises in whole or in part from the Website shall be decided
            exclusively by a court of competent jurisdiction located in Los
            Angeles County, California.
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            Disputes Resolution
            <Typography variant="body1">
              If You have any concern or dispute about the Service, You agree to
              first try to resolve the dispute informally by contacting the
              Company in writing.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            For European Union (EU) Users
            <Typography variant="body1">
              If You are located in the European Union, you may benefit from any
              mandatory provisions of the law of the country in which you are
              resident in.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            United States Legal Compliance
            <Typography variant="body1">
              You represent and warrant that (i) You are not located in a
              country that is subject to the United States government embargo,
              or that has been designated by the United States government as a
              "terrorist supporting" country, and (ii) You are not listed on any
              United States government list of prohibited or restricted parties.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography gutterBottom variant="h4">
            Severability and Waiver
          </Typography>
          <br />
          <Typography gutterBottom variant="h4">
            Severability
            <Typography variant="body1">
              If any provision of these Terms and Conditions is deemed invalid
              by a court of competent jurisdiction, the invalidity of such
              provision shall not affect the validity of the remaining
              provisions of these Terms and Conditions, which shall remain in
              full force and effect..
            </Typography>
          </Typography>
          <br />
          <Typography gutterBottom variant="h4">
            Waiver
            <Typography variant="body1">
              The failure to exercise a right or to require performance of an
              obligation under these Terms shall not effect a party's ability to
              exercise such right or require such performance at any time
              thereafter nor shall be the waiver of a breach constitute a waiver
              of any subsequent breach.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            Translation Interpretation
            <Typography variant="body1">
              These Terms and Conditions may have been translated if We have
              made them available to You on our Service. You agree that the
              original English text shall prevail in the case of a dispute.
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            Changes to These Terms and Conditions
            <Typography variant="body1">
              Company reserves the right, in its sole discretion, to modify
              these Terms and Conditions at any time by posting a notice on the
              website, or by sending You a notice via email. You shall be
              responsible for reviewing and becoming familiar with any such
              modifications. Such modifications are effective upon first posting
              or notification, and use of the Website or any Services by You
              following such posting or notification constitutes your acceptance
              of the Terms and Conditions as modified. You agree to review these
              Terms and Conditions regularly to ensure that You are updated as
              to any changes.{" "}
            </Typography>
          </Typography>
          <br />
          <br />

          <Typography variant="h4">
            Contact Us
            <Typography variant="body1">
              If you have any questions about these Terms and Conditions, You can contact us:<br />
              <li>
                By email: <a href="mailto:info@docplus.com">info@docplus.com</a>
              </li>
              <br />
              <a href="/privacy-policy/"> Find out more </a><br />
              © Documentary Plus, LLC, All Rights Reserved.
            </Typography>
          </Typography>
          <hr />
        </NoSsr>
      </section>
    </Layout>
  );
}
